<template>
	<div id="main-area">
		<Header />
		<router-view />
	</div>
</template>

<script>
	import { fireSignIn } from './db';

	import Header from './components/Header';

	export default {
		components: {
			Header
		},
		mounted() {
			fireSignIn();

			// FireStorageGetAll()
			//     .then((images) => {
			//         Images.forEach((image) => {
			//             If (isTimestampExpired(getTimestampFromStoragePath(image))) {
			//                 FireStorageDelete(image);
			//             }
			//         });
			//     })
			//     .catch((e) => {
			//         Console.error(`getAll Storage Error ${e}`);
			//     });
			//
			// FireGetAll()
			//     .then((allOrders) => {
			//         AllOrders.forEach((order) => {
			//             If (isTimestampExpired(order[0].timestamp)) {
			//                 FireDelete(order[1]);
			//             }
			//         });
			//     })
			//     .catch((e) => {
			//         Console.error(`getAll Error ${e}`);
			//     });
		}
	};
</script>

<style>
	@import './assets/flexboxgrid/dist/flexboxgrid.min.css';

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Black.ttf');
		font-weight: 900;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf');
		font-weight: 900;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Bold.ttf');
		font-weight: 700;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf');
		font-weight: 700;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf');
		font-weight: 800;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf');
		font-weight: 800;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf');
		font-weight: 200;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf');
		font-weight: 200;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Italic.ttf');
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Light.ttf');
		font-weight: 300;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-LightItalic.ttf');
		font-weight: 300;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Medium.ttf');
		font-weight: 500;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf');
		font-weight: 500;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf');
		font-weight: 400;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
		font-weight: 600;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf');
		font-weight: 600;
		font-style: italic;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-Thin.ttf');
		font-weight: 100;
	}

	@font-face {
		font-family: Montserrat;
		src: url('./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf');
		font-weight: 100;
		font-style: italic;
	}

	#main-area {
		overflow-x: hidden;
	}

	:root {
		--light-grey: #ebedee;
		--white: #fff;
		--black: #333c4b;
		--text: #6a707c;
		--accent: #0abab5;
		--accent-blue: #bbd2d8;
		--green: #156878;
		--blue: #1e6c93;
		--accent-two: #61d3d0;
	}

	html,
	body {
		margin: 0;
		padding: 0;
	}

	* {
		font-family: 'Montserrat', sans-serif !important;
	}

	input,
	textarea {
		width: 90%;
		border: none;
		border: 1px solid var(--light-grey);
		padding: 8px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2px;
		margin-bottom: 2px;
		font-size: 0.8rem;
		color: var(--text);
	}

	textarea {
		resize: vertical;
	}

	.hidden-validator {
		font-size: 0;
		height: 0;
		width: 0;
		padding: 0 !important;
		display: block;
		margin: 0 auto !important;
		line-height: 0;
	}

	.vc {
		display: flex;
		align-items: center;
	}

	button,
	.button {
		background-color: var(--accent);
		color: var(--white) !important;
		border: none;
		padding: 10px 30px;
		font-size: 1rem;
		border-radius: 5px;
		text-decoration: none;
	}

	button:hover,
	.button:hover,
	button:active,
	.button:active {
		cursor: pointer;
		opacity: 0.8;
		color: var(--light-grey);
	}

	h1 {
		font-weight: 400;
	}

	h2,
	h3,
	h4,
	h5 {
		font-weight: 300;
	}

	p,
	li,
	a {
		font-weight: 400;
	}

	/** {
	outline: 1px solid red;
}*/
</style>
