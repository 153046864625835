import {createRouter, createWebHistory} from "vue-router";

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "about" */ "../views/Home"),
	},
	{
		path: "/get-started",
		name: "GetStarted",
		component: () => import(/* webpackChunkName: "about" */ "../views/Form"),
	},
	{
		path: "/order/:orderId",
		name: "Order",
		props: true,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.

		component: () => import("../views/Order"),
	},
	{
		path: "/thank-you/:orderId",
		name: "ThankYou",
		props: true,
		component: () => import("../views/ThankYou"),
	},
	{
		path: "/success",
		name: "Success",
		component: () => import("../views/Success"),
	},
	{
		path: "/cancel/:orderId/:customDeletionId",
		name: "Cancel",
		props: true,
		component: () => import("../views/Cancel"),
	},
	{
		path: "/pricing",
		name: "Pricing",
		component: () => import("../views/Pricing"),
	},
	{
		path: "/admin",
		name: "Admin",
		component: () => import("../views/Admin"),
	},
	{
		path: "/brand-kit/:orderId",
		name: "BrandKit",
		props: true,
		component: () => import("../views/BrandKit"),
	},
	{
		path: "/brand_kit/thank_you/:orderId",
		name: "BrandKitThankYou",
		props: true,
		component: () => import("../views/brand-kit/BrandKitThankYou"),
	},
	{
		path: "/brand_kit/cancel/:orderId",
		name: "BrandKitCancel",
		props: true,
		component: () => import("../views/brand-kit/BrandKitCancel"),
	},
	{
		path: "/terms",
		name: "Terms",
		component: () => import("../views/Terms"),
	},
	{
		path: "/ehdot",
		name: "TermsFi",
		component: () => import("../views/TermsFi.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	// eslint-disable-next-line no-unused-vars
	scrollBehavior: function (to, from, savedPosition) {
		if (to.hash) {
			return {el: to.hash};
		} else {
			return {x: 0, y: 0};
		}
	},
	routes,
});

router.beforeEach(() => {
	window.scrollTo(0, 0);
});

export default router;
