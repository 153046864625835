<template>
	<nav class="row vc">
		<div class="col-sm-1 col-xs-2"></div>
		<div id="title-part" class="col-sm-10 col-xs-8 vc">
			<img
				src="../assets/main-logo.png"
				alt="Custom Branding PRO"
				@click="navigateToHome"
			/>
		</div>
		<div class="col-sm-1 col-xs-2 vc" style="text-align: right">
			<button @click="showNav = !showNav">
				<i class="fas fa-bars"></i>
			</button>
		</div>
		<div id="items" class="col-xs-12 row" v-if="showNav">
			<div class="col-md-11 col-xs-12">
				<div class="row">
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<router-link
							@click="showNav = false"
							:to="
								$route.name === 'Pricing' ? '/#about' : '#about'
							"
							>About
						</router-link>
					</div>
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<router-link
							@click="showNav = false"
							:to="
								$route.name === 'Pricing'
									? '/#testimonials'
									: '#testimonials'
							"
							>Testimonials
						</router-link>
					</div>
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<a
							@click="showNav = false"
							href="https://www.canva.com/design/DAEwEILIJh4/oO2PAIJXaCHvZXSr3QVy0A/view#1"
							target="_blank"
							>Case Studies
						</a>
					</div>
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<router-link
							@click="showNav = false"
							:to="
								$route.name === 'Pricing'
									? '/#brand-kit'
									: '#brand-kit'
							"
							>What is a brand kit?
						</router-link>
					</div>
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<router-link
							@click="showNav = false"
							:to="$route.name === 'Pricing' ? '#' : '/pricing'"
							>Pricing
						</router-link>
					</div>
					<div class="item-holder col-md-2 col-sm-6 col-xs-12">
						<router-link
							@click="showNav = false"
							:to="
								$route.name === 'Pricing'
									? '/#contact'
									: '#contact'
							"
							>Contact
						</router-link>
					</div>
				</div>
			</div>
			<div class="col-md-1 col-xs-12">
				<div class="row">
					<div class="item-holder col-xs-12">
						<router-link
							@click="showNav = false"
							:to="'/get-started'"
							target="_blank"
							>Sign Up
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'Header',
		data() {
			return {
				showNav: false
			};
		},
		methods: {
			navigateToHome() {
				window.location = '/';
			}
		}
	};
</script>

<style scoped>
	nav {
		width: 100%;
		margin: 0;
		padding: 20px 20px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99;
		background-color: var(--accent);
	}

	.vc {
		display: flex;
		align-items: center;
	}

	img {
		height: 5vh;
		margin: 0 auto;
	}

	img:hover {
		cursor: pointer;
	}

	button {
		margin: 0 auto;
	}

	#items {
		padding-top: 25px;
		margin: 0 auto;
	}

	.item-holder {
		text-align: center;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	#items a {
		color: var(--light-grey);
		text-decoration: none;
	}

	#items a:hover,
	#items a:active {
		color: var(--white);
		cursor: pointer;
	}
</style>
