import {auth, db, fireStorage} from "./firebaseInit";

export function fireSignIn() {
	auth.signInAnonymously()
		.then(() => {
			console.log("signIn Success!");
		})
		.catch((e) => {
			console.error(`signIn Error ${e}`);
		});
}

export function asyncFireSignIn() {
	return auth.signInAnonymously()
		.then(() => {
			console.log("Sign-in Success!");
		})
		.catch((e) => {
			console.error(`Sign-in Error: ${e}`);
			throw e; // Rethrow the error so it can be handled by the caller
		});
}


export function fireAsyncSignIn() {
	return auth.signInAnonymously();
}

export function firePost(doc, data, collection = "orders") {
	db.collection(collection).doc(doc).set(data)
		.then(() => {
			console.log("post Successful!");
		})
		.catch((e) => {
			console.error(`post Error ${e}`);
		});
}

export function asyncFirePost(doc, data, collection = "orders") {
	return db.collection(collection).doc(doc).set(data)
		.then(() => {
			console.log("Post Successful!");
		})
		.catch((e) => {
			console.error(`Post Error: ${e}`);
			throw e; // Rethrow the error to be handled by the caller
		});
}


export function fireDocExists(docName, collection = "orders") {
	db.collection(collection).doc(docName).get()
		.then((doc) => {
			if (doc.exists) {
				return true;
			}
		})
		.catch((e) => {
			console.error(`exists Error ${e}`);
		});

	return false;
}

export function fireGet(id, collection = "orders") {
	return db.collection(collection).doc(id).get()
		.then((doc) => doc.data())
		.catch((e) => {
			console.error(`get Error ${e}`);
		});
}

export async function fireGetAll(collection = "orders") {
	const ref = db.collection(collection);
	const snapshot = await ref.get();

	const result = [];
	snapshot.forEach((doc) => {
		result.push([doc.data(), doc.id]);
	});

	return result;
}

export function getTimestampFromStoragePath(ref, collection = "orders") {
	const id = ref.fullPath.replace(`${collection}/`, '');

	let tmp = '';
	for (const char of id) {
		if (char === "_") {
			break;
		}

		tmp += char;
	}

	return tmp;
}

export function fireStorageGetAll(collection = "orders") {
	return fireStorage.ref(collection).listAll()
		.then((res) => res.items);
}

export function firePut(id, data, collection = "orders") {
	db.collection(collection).doc(id).update(data)
		.then(() => {
			console.log("put Successful!");
		})
		.catch((e) => {
			console.error(`put Error ${e}`);
		});
}

export function fireDelete(id, collection = "orders") {
	db.collection(collection).doc(id).delete()
		.then(() => {
			console.log("delete Successful!");
		})
		.catch((e) => {
			console.error(`delete Error ${e}`);
		});
}

export function fireStorageDelete(imageRef) {
	fireStorage.ref().child(imageRef.fullPath).delete()
		.then(() => {
			console.log("storage delete Success!");
		})
		.catch((e) => {
			console.error(`storage delete Error ${e}`);
		});
}

export function fireUploadFile(file, id, bucket = "orders", then = () => {
}) {
	const storageRef = fireStorage.ref();
	const fileRef = storageRef.child(`${bucket}/${id}_${file.name}`);

	fileRef.put(file, {id})
		.then(() => {
			console.log("upload Success!");
			then();
		})
		.catch((e) => {
			console.error(`upload Error ${e}`);
		});

	return "";
}

export function asyncFireUploadFile(file, id, bucket = "orders") {
	const storageRef = fireStorage.ref();
	const fileRef = storageRef.child(`${bucket}/${id}_${file.name}`);

	return new Promise((resolve, reject) => {
		fileRef.put(file, { id })
			.then(() => {
				console.log("Upload Success!");
				resolve(); // Resolve the promise when upload is successful
			})
			.catch((e) => {
				console.error(`Upload Error ${e}`);
				reject(e); // Reject the promise if there is an error
			});
	});
}


export function fireUploadFormFiles(
		files,
		id,
		bucket = "orders",
		then = () => {
		}
) {
	try {
		files.forEach((file) => {
			fireUploadFile(file, id, bucket, then);
		});
	} catch (e) {
		console.error(e);
	}
}

export function asyncFireUploadFormFiles(files, id, bucket = "orders") {
	return Promise.all(
		files.map((file) => {
			return asyncFireUploadFile(file, id, bucket);
		})
	);
}


export function fireDeleteOrderById(id, collection = "orders") {
	fireStorageGetAll()
			.then((images) => {
				images.forEach((image) => {
					if (image.name.length > id.length) {
						if (image.name.substring(0, id.length) === id) {
							fireStorageDelete(image);
						}
					}
				});
			})
			.catch((e) => {
				console.error(`deleteOrderById getAll Storage Error ${e}`);
			});

	fireGetAll()
			.then((allOrders) => {
				allOrders.forEach((order) => {
					if (order[1] === id) {
						fireDelete(order[1], collection);
					}
				});
			})
			.catch((e) => {
				console.error(`deleteOrderById getAll Error ${e}`);
			});
}
